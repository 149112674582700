import {isMobile} from 'react-device-detect';
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import processFile from "./Parsing"
import UploadLogo from "./static/upload"

class FileListScroller extends React.Component {

    constructor(props) {
        super(props)
        this.messagesEndRef = React.createRef()
    }


    componentDidMount () {
        this.scrollToBottom()
    }
    componentDidUpdate () {
        this.scrollToBottom()
    }
    scrollToBottom() {
        this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    render() {
        const listitems = this.props.listitems.map(fname => <span key={fname}>Loading {fname}...<br/></span>)
        return <code className="filelist">
            {listitems}
            <span ref={this.messagesEndRef} />
        </code>
    }
}


function FileContent(props) {
    if (props.file === null) {

        const dropper = (
            <div className="fileUpload">
                <div>{isMobile ? 'Tap to upload your export.zip' : 'Drag your export.zip file here, or click to add it.'}</div>
                <UploadLogo />
            </div>
        )

        return <FileUploader
            handleChange={props.handleChange}
            name="file"
            types={["ZIP"]}
            label="Drag your export.zip file here, or click to upload"
            classes="uploadDrop"
            children={dropper}
        />
    }

    const progress = Math.floor(100 * props.filelist.length / props.total_files)

    return <div>
        <FileListScroller listitems={props.filelist} />
        <div className="progress mb-3" style={{height: 'auto'}}>
            <div
                className="progress-bar progress-bar-striped progress-bar-animated pt-2 pb-2"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                    width: progress + '%'
                }}
            />
            <span className="progressText">Loading Activities...</span>
        </div>
    </div>
}

function Upload(props) {
  const [file, setFile] = useState(null)
  const [filelist, setFileList] = useState([])
  const [total_files, setTotalFiles] = useState(Infinity)

  const handleChange = async (file) => {
    setFile(file)
    processFile(file, setFileList, setTotalFiles).then((result) => {
        if(result.error) {
            window.rollbar.error(`${result.error}\n${result.errorDetail}`)
        } else {
            props.next({
                bounds: result.bounds,
                activities: result.activities,
            })
        }
    })
  }

  return (
    <div>
        <div className="row">
            <div className="col-sm">
                <p>
                    To create your Waytracer artwork,
                    first <a
                        href="https://www.strava.com/athlete/delete_your_account"
                        target="_blank"
                        rel="noreferrer"
                    >
                        download your Strava export from this page
                    </a> by clicking 'Request your Archive'. You will then get a zip file. Once you have it, upload it here.
                </p>
                <p>
                    <strong>Your data will not leave your device.</strong> This tool works entirely offline, no data is
                    transferred to this or any other site.
                </p>
            </div>
            <div className="col-sm">
                <FileContent
                    handleChange={handleChange}
                    file={file}
                    filelist={filelist}
                    total_files={total_files}
                />
            </div>
        </div>
    </div>
  );
}

export default Upload;
