import React from "react";

export default class DataRenderer extends React.Component {


    render() {
        const activities = this.props.activities
        const bounds = this.props.bounds
        const editor_state = this.props.editor_state

        const included = (a) => {
            const in_bounds = a.bounds.does_overlap(bounds)
            if(!editor_state.activity_types) {
                return in_bounds
            }
            return in_bounds && editor_state.activity_types[a.metadata["Activity Type"]]
        }

        let total_distance = 0
        let total_activities = 0
        const start_date = activities[0].timestamp
        let end_date = activities[activities.length - 1].timestamp

        activities.filter(included).forEach(activity => {
            total_activities += 1
            total_distance += parseFloat(activity.metadata.Distance)
        })

        return (
            <>
            <h5>Statistics</h5>
            <div className="row">
                <div className="col">Total Distance</div><div className="col">{Math.round(total_distance / 1000)}km</div>
                <div className="w-100"></div>
                <div className="col">Total Activities</div><div className="col">{total_activities}</div>
                <div className="w-100"></div>
                <div className="col">Dates</div><div className="col">{start_date.toLocaleDateString()} - {end_date.toLocaleDateString()}</div>
            </div>
            </>
        )
    }
}
