import React from 'react'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { useMapEvents } from 'react-leaflet/hooks'

import {Bounds} from "./utils"

import '../node_modules/leaflet/dist/leaflet.css'
import '../node_modules/leaflet-draw/dist/leaflet.draw.css'

class MapLayer extends React.Component {

    constructor(props) {
        super(props)

        const lat_top = props.bounds.top_left.lat
        const lat_bottom = props.bounds.bottom_right.lat
        const lng_left = props.bounds.top_left.lng
        const lng_right = props.bounds.bottom_right.lng

        this.state = {
            bounds: [[lat_top, lng_left], [lat_bottom, lng_right]],
            center: {lat: (lat_top + lat_bottom) / 2, lng: (lng_left + lng_right) / 2},
        }
    }

    render() {
        const colors = this.props.editor_state.activity_colors
        const has_color = colors && Object.keys(colors).length
        const markers = (has_color) ? this.props.activities.map(
            (a, i) => <Circle
                center={a.center}
                radius={200}
                key={i}
                pathOptions={{
                    color: colors[a.metadata["Activity Type"]],
                    fill: colors[a.metadata["Activity Type"]]
                }}
            />
        ) : null

        const onUpdateBounds = (bounds) => {
            this.props.updateBounds(Bounds.fromLeaflet(bounds))
        }

        return (
            <div>
                <h5>Image Boundary</h5>
                <p>Position the map to the area you'd like for your image.</p>
                <MapContainer
                    center={this.state.center}
                    bounds={this.state.bounds}
                    zoomSnap={0.6}
                    editable={true}
                    whenReady={(map) => onUpdateBounds(map.target.getBounds())}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers}
                    <EventHandler updateBounds={onUpdateBounds} />
                </MapContainer>
            </div>
        )
    }
}

function EventHandler(props) {
    const update = () => props.updateBounds(map.getBounds())
    const map = useMapEvents({
      zoomend: update,
      moveend: update,
    })
  }

export default MapLayer;
