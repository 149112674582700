export default function DonationPanel(props) {
    return <div
        className="donation-overlay"
        onClick={props.dismiss}
    >
        <div style={{
            width: '30rem',
            background: '#fff',
            borderRadius: '2rem',
            marginTop: '5rem',
            marginLeft: 'calc(50% - 15rem)',
            textAlign: 'center',
            padding: '2rem'
        }}>
            <h5 className="mb-4">This tool is free, but please consider donating</h5>
            <p className="mb-4">
                This site has no ads or tracking,<br></br>the only support is from donors.
            </p>
            <a
                href='https://ko-fi.com/T6T546AAC'
                target='_blank'
                rel='noreferrer'
            >
                <img
                    height='36'
                    style={{border:'0px', height:'36px;'}}
                    src='https://uploads-ssl.webflow.com/5c14e387dab576fe667689cf/61e11d503cc13747866d338b_Button-2.png'
                    border='0'
                    alt='Buy Me a Coffee at ko-fi.com' />
            </a>
            <br></br>
            <button className="btn btn-outline-danger mt-3" type="button" onClick={props.dismiss}>Not this time</button>
            </div>
        </div>
}
