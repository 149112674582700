import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="105"
      version="1.1"
      viewBox="0 0 383.108 403.194"
    >
      <path
        fill="#EBB11B"
        fillOpacity="1"
        stroke="none"
        strokeOpacity="1"
        d="M148.258 390.334H39.508c-15.613 0-28.309-12.699-28.309-28.309V39.505c0-15.605 12.7-28.3 28.31-28.3h28.608v42.128H38.262a5.604 5.604 0 00-5.602 5.602v3.91a5.604 5.604 0 005.602 5.602h29.855v24.816H38.262a5.604 5.604 0 00-5.602 5.601v3.91a5.604 5.604 0 005.602 5.602h29.855v24.816H38.262a5.604 5.604 0 00-5.602 5.602v3.902a5.604 5.604 0 005.602 5.601h29.855v28.645H48.59a5.604 5.604 0 00-5.602 5.602v70.367a5.599 5.599 0 005.602 5.602h50.254a5.602 5.602 0 005.602-5.602v-70.367a5.604 5.604 0 00-5.602-5.602H79.317v-35.34h29.86a5.604 5.604 0 005.6-5.601v-3.91a5.604 5.604 0 00-5.6-5.602h-29.86v-24.816h29.86a5.604 5.604 0 005.6-5.602v-3.91a5.604 5.604 0 00-5.6-5.601h-29.86V61.744h29.86a5.604 5.604 0 005.6-5.602V52.24a5.604 5.604 0 00-5.6-5.602h-29.86V11.204h121.7v76.484a5.602 5.602 0 005.602 5.602h76.484v40.234a5.602 5.602 0 1011.203 0V88.23c.015-.184.113-.352.113-.543 0-1.887-.93-3.555-2.36-4.57l-81.476-81.47c-.012-.003-.016-.011-.028-.015-.464-.465-1.007-.801-1.578-1.07-.172-.086-.351-.125-.543-.192a5.585 5.585 0 00-1.457-.297c-.125-.011-.23-.074-.359-.074H39.508C17.723 0 0 17.723 0 39.504v322.52c0 21.785 17.723 39.508 39.508 39.508h108.75a5.602 5.602 0 005.602-5.602 5.604 5.604 0 00-5.606-5.597zm-94.066-143.04v-31.71h39.055v31.71z"
      ></path>
      <path
        fill="#EBB11B"
        fillOpacity="1"
        stroke="none"
        strokeOpacity="1"
        d="M274.748 186.514c-59.742 0-108.34 48.602-108.34 108.34 0 59.738 48.602 108.34 108.34 108.34 59.746 0 108.36-48.609 108.36-108.35-.004-59.742-48.609-108.34-108.36-108.34zm0 205.48c-53.562 0-97.145-43.578-97.145-97.148 0-53.57 43.574-97.145 97.145-97.145 53.57 0 97.156 43.574 97.156 97.145-.004 53.57-43.582 97.148-97.156 97.148z"
      ></path>
      <path
        fill="#EBB11B"
        fillOpacity="1"
        stroke="none"
        strokeOpacity="1"
        d="M279.088 249.354c-.055-.066-.133-.086-.191-.14-1.032-1.153-2.477-1.915-4.145-1.915-1.668 0-3.125.762-4.144 1.914-.055.063-.133.079-.192.141l-23.34 28.566a5.6 5.6 0 004.336 9.145h17.742v49.766a5.596 5.596 0 005.602 5.601 5.596 5.596 0 005.601-5.601l-.004-49.766h17.754c.043.004.09 0 .114 0a5.596 5.596 0 005.601-5.602 5.59 5.59 0 00-1.953-4.25z"
      ></path>
    </svg>
  );
}

export default Icon;
