import Form from 'react-bootstrap/Form';
import React from "react";

import ColorPicker from './ColorPicker';

const PALLETTE = ["#8f00ce", "#F51D1E", "#009DFF", "#43CD48", "#EBB11B"]

export default class EditControls extends React.Component {

    constructor(props) {
        super(props)

        const activity_types = {}
        const activity_colors = {}

        props.activities.forEach((a) => {
            activity_types[a.metadata["Activity Type"]] = true
        })
        Object.keys(activity_types).forEach((type, idx) => activity_colors[type] = PALLETTE[idx % PALLETTE.length])

        this.state = {
            age_colour: false,
            age_color_old: '#f00',
            age_color_new: '#0f0',
            activity_types: activity_types,
            activity_colors: activity_colors,
            distance_threshold: 0,
        }

        this.props.updateEditorState(this.state)
    }

    componentDidUpdate(_, prevState) {
        const state_did_change = !!Object.keys(this.state)
            .find(k => prevState[k] !== this.state[k])
        if(state_did_change){
            this.props.updateEditorState(this.state)
        }
    }

    render() {
        const cls = this
        return <div>
            <h5 className="mt-4">Activity Types</h5>
            {Object.entries(this.state.activity_types).map(e => {
                const [type, enabled] = e
                return (
                    <div className="row" key={type}>
                        <div className="col"><Form.Check
                            key={"activity-type-" + type}
                            type="switch"
                            label={type}
                            checked={enabled}
                            onChange={() => {
                                const activity_types = {...cls.state.activity_types}
                                activity_types[type] = !activity_types[type]
                                cls.setState({activity_types: activity_types})
                            }}
                        /></div>
                        <div className="col">
                            <ColorPicker
                                defaultColor={this.state.activity_colors[type]}
                                disabled={this.state.age_colour}
                                onChange={(color) => {
                                    const activity_colors = {...cls.state.activity_colors}
                                    activity_colors[type] = color.hex
                                    this.setState({activity_colors: activity_colors})
                                }}
                            />
                        </div>
                        <div className="w-100"></div>
                    </div>
                )}
            )}
            <h5 className="mt-4">Color Activities By Age</h5>
            <Form.Check
                type="switch"
                label="Colour traces by age"
                checked={this.state.age_colour}
                onChange={(ev) => {
                    this.setState({age_colour: ev.target.checked})
                }}
            />
            <div className="row" style={{
                height: this.state.age_colour ? 'auto' : 0,
                overflow: 'hidden',
                transition: 'height 0.3s ease-in-out'
            }}>
                <div className="col">
                    <label>Oldest Activity</label>
                </div>
                <div className="col">
                    <ColorPicker
                        defaultColor={this.state.age_color_old}
                        onChange={(color) => this.setState({age_color_old: color.hex})}
                    />
                </div>
                <div className="w-100"></div>
                <div className="col">
                    <label>Newest Activity</label>
                </div>
                <div className="col">
                    <ColorPicker
                        defaultColor={this.state.age_color_new}
                        onChange={(color) => this.setState({age_color_new: color.hex})}
                    />
                </div>
            </div>
            <div>
                <h5 className="mt-4">Data Cleaning</h5>
                <Form.Label>Distance Threshold - {this.state.distance_threshold > 0 ? this.state.distance_threshold + 'm' : "Disabled"}</Form.Label>
                <Form.Range
                    onChange={(ev) => {
                        this.setState({distance_threshold: ev.target.value})
                    }}
                    min={0}
                    max={1000}
                    step={50}
                    value={this.state.distance_threshold}
                />
                <p>
                    If you have GPS errors in your traces, adjust this threshold to remove them. Two points that are further apart than this distance will not be connected.
                </p>
            </div>
        </div>
    }
}
