export function project(lon, lat) {
    var x = (lon * 20037508.34) / 180;
    var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180;
    return [x, y];
}

const toRadian = angle => (Math.PI / 180) * angle
const distance = (a, b) => (Math.PI / 180) * (a - b)

export function distance_km (p1, p2) {
    const RADIUS_OF_EARTH_IN_KM = 6371

    const dLat = distance(p2.lat, p1.lat)
    const dLon = distance(p2.lng, p1.lng)

    const lat1 = toRadian(p1.lat)
    const lat2 = toRadian(p2.lat)

    // Haversine Formula
    const a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.asin(Math.sqrt(a))

    return RADIUS_OF_EARTH_IN_KM * c
  }

export class Point {
    constructor(lng, lat) {
        this.lng = lng
        this.lat = lat
    }

    static deserialize(obj) {
        return new Point(obj.lng, obj.lat)
    }

    project() {
        return project(this.lng, this.lat)
    }

    equals(other_point) {
        return (
            this.lng === other_point.lng &&
            this.lat === other_point.lat
        )
    }
}

export class Bounds {
    constructor(top_left, bottom_right) {
        this.top_left = top_left
        this.bottom_right = bottom_right
    }

    static deserialize(obj) {
        return new Bounds(
            Point.deserialize(obj.top_left),
            Point.deserialize(obj.bottom_right),
        )
    }

    static fromLeaflet(bounds) {
        return new Bounds(
            new Point(bounds._southWest.lng, bounds._northEast.lat),
            new Point(bounds._northEast.lng, bounds._southWest.lat)
        )
    }

    does_overlap(other_bounds) {
        return (
            this.top_left.lng < other_bounds.bottom_right.lng &&
            this.bottom_right.lng > other_bounds.top_left.lng &&
            this.top_left.lat > other_bounds.bottom_right.lat &&
            this.bottom_right.lat < other_bounds.top_left.lat
        )
    }

    extend(point) {
        this.top_left.lng = Math.min(point.lng, this.top_left.lng)
        this.bottom_right.lng = Math.max(point.lng, this.bottom_right.lng)

        this.top_left.lat = Math.max(point.lat, this.top_left.lat)
        this.bottom_right.lat = Math.min(point.lat, this.bottom_right.lat)
    }

    center() {
        return new Point(
            (this.top_left.lng + this.bottom_right.lng) / 2,
            (this.bottom_right.lat + this.bottom_right.lat) / 2
        )
    }

    equals(other_bounds) {
        return (
            this.top_left.equals(other_bounds.top_left) &&
            this.bottom_right.equals(other_bounds.bottom_right)
        )
    }

    static infinite_bounds() {
        return  new Bounds(new Point(Infinity, -Infinity), new Point(-Infinity, Infinity))
    }
}

export const filename_to_id = (fname) => fname.split('/').pop().split(".")[0]