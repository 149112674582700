import { presets, Canvg } from 'canvg';
import React from "react"
import ReactDOMServer from 'react-dom/server';
import DonationPanel from './DonationPanel';

export default class SvgRenderer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            viewBox: [100, 100],
            paths: [],
            rendering: true,
            shown_popup: false,
            show_popup: false,
        }

        this.worker = new Worker(new URL('./render.worker.js', import.meta.url))
        this.worker.onmessage = (msg) => {
            this.setState({
                viewBox: msg.data.viewBox,
                paths: msg.data.paths,
                rendering: false,
            })
        }
    }

    render_svg(){
        if(!Object.keys(this.props.editor_state).length){
            return
        }
        this.worker.postMessage({
            activities: this.props.activities,
            bounds: this.props.bounds,
            editor_state: this.props.editor_state,
        })
    }

    componentDidMount() {
        this.render_svg()
    }

    componentDidUpdate(prevProps) {
        const controls_did_change = prevProps &&
            this.props.editor_state &&
            !!Object
                .keys(this.props.editor_state)
                .find(k => prevProps.editor_state[k] !== this.props.editor_state[k])

        if(!prevProps.bounds.equals(this.props.bounds) || controls_did_change){
            this.setState({rendering: true})
            this.render_svg()
        }
    }

    download_svg() {
        if(!this.state.shown_popup){
            this.setState({
                shown_popup: true,
                show_popup: true,
            })
        }
        const element = document.createElement("a")
        let doc = document.getElementById('svg').outerHTML
        const height = this.state.viewBox[1]
        doc = doc.replace("</svg>", `<style>.watermark {font: bold 12px sans-serif; fill: #555; }</style><text class="watermark" x="10" y="${height - 15}">Made with waytracer.mamota.net</text></svg>`)
        const file = new Blob([doc], {type: 'image/svg+xml'})
        element.href = URL.createObjectURL(file)
        element.download = "waytracer.svg"
        document.body.appendChild(element)
        element.click()
    }

    async download_png(width, height, paths) {
        if(!this.state.shown_popup){
            this.setState({
                shown_popup: true,
                show_popup: true,
            })
        }
        const canvas = new OffscreenCanvas(width * 10, height * 10)
        const ctx = canvas.getContext('2d')
        const doc = ReactDOMServer.renderToStaticMarkup(<svg
            id="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${width} ${height}`}
            width={width * 10}
            height={height * 10}
            ref={this.svg_ref}>
                {paths}
            <style>{`
                .watermark {
                    font: bold 12px sans-serif;
                    fill: #555;
                }`}
            </style>
            <text
                class="watermark"
                x="10"
                y={height - 15}
            >
                Made with waytracer.mamota.net
            </text>
        </svg>)
        const c = await Canvg.from(ctx, doc, presets.offscreen())
        await c.render()
        const blob = await canvas.convertToBlob()
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = "waytracer.png"
        document.body.appendChild(link)
        link.click()

    }

    render() {
        const popup = (this.state.show_popup) ? <DonationPanel dismiss={() => this.setState({show_popup: false})} /> : null
        const paths = this.state.paths.map((d, i) => {
            return <path
                key={`path-${i}`}
                stroke={d.stroke}
                fill="none"
                d={d.path}
            />
        })

        const height = 500
        const [viewWidth, viewHeight] = this.state.viewBox

        const scaleFactor = height / viewHeight
        const width = scaleFactor * viewWidth


        return (
            <div className="stickycol">
                <h5>Your Traces</h5>
                <p>Hint: If it looks blank, try zooming in on a cluster of activities.</p>
                <div
                    className="svgContainer"
                >
                        <svg
                            id="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox={`0 0 ${viewWidth} ${viewHeight}`}
                            width={width}
                            height={height}
                            ref={this.svg_ref}>
                                {paths}
                        </svg>
                <div className={this.state.rendering ? "overlay" : "overlay hidden"}><div className="content">Reticulating Splines</div></div>
                </div>
                <div className="w-100"></div>
                <div className="btn-group">
                    <button className="btn btn-outline-primary" onClick={() => this.download_svg()}>Download SVG</button>
                    <button className="btn btn-outline-primary" onClick={() => this.download_png(viewWidth, viewHeight, paths)}>Download PNG</button>
                </div>
                {popup}
            </div>
          )
    }
}
