

import {BlobReader, BlobWriter, ZipReader} from "@zip.js/zip.js";

import {filename_to_id, Point, Bounds} from './utils'

const TRACK_TYPES = [".gpx.gz", ".gpx", ".fit.gz", ".fit", "activities.csv"]

const valid_file = (fname) => (
    fname.startsWith("activities") &&
    TRACK_TYPES.some((suffix) => fname.toLowerCase().endsWith(suffix))
)

export default async function processFile(file, setFileList, setTotalFiles) {
    const reader = new ZipReader(new BlobReader(file))
    const entries = await reader.getEntries()
    const validentries = entries.filter(e => valid_file(e.filename))
    if (validentries.length === 0) {
        return {
            error: "No valid entries found in this zip",
            errorDetail: "Entries:\n" + entries.map(e => JSON.stringify(e)).join("\n")
        }
    }
    setTotalFiles(validentries.length)
    const global_bounds = Bounds.infinite_bounds()

    const processedFiles = []
    let activities = []

    const results = {}
    let metadata = null

    const resultsPromise = new Promise((resolve, reject) => {
        const worker = new Worker(new URL('./parser.worker.js', import.meta.url))

        worker.onmessage = (msg) => {
            results[msg.data.filename] = true
            if (msg.data.filename === "activities.csv"){
                metadata = msg.data.data
            } else {
                const bounds = Bounds.deserialize(msg.data.bounds)
                const lines = msg.data.lines.map(line => line.map(d => Point.deserialize(d)))
                if(lines && lines.length > 0){
                    global_bounds.extend(bounds.top_left)
                    global_bounds.extend(bounds.bottom_right)
                    activities.push({
                        bounds: bounds,
                        lines: lines,
                        filename: msg.data.filename,
                        id: filename_to_id(msg.data.filename),
                        center: bounds.center()
                    })
                }
            }
            processedFiles.push(msg.data.filename)
            setFileList(processedFiles.slice())
            if(processedFiles.length === validentries.length) {
                activities = activities.map(a => {
                    a.metadata = metadata[a.id]
                    if(!a.metadata) return null
                    a.timestamp = new Date(a.metadata["Activity Date"])
                    return a
                }).filter(a => a !== null)
                resolve()
            }
        }

        validentries.forEach(async entry => {
            const writer = new BlobWriter()
            const blob = await entry.getData(writer)
            results[entry.filename] = false
            worker.postMessage({
                blob: blob,
                filename: entry.filename,
            })
        })
    })

    await resultsPromise

    return {
        bounds: global_bounds,
        activities: activities,
    }
}
