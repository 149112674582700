import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Rollbar from 'rollbar';
import { Provider, ErrorBoundary } from '@rollbar/react'

import Upload from './Upload.js'

import './App.scss'
import Logo from "./static/logo"
import Editor from './Editor'
import Trace from './static/trace.js'

const rollbarConfig = {
  accessToken: '5d5e7064b8ef489aa3e714b1a9d9f6ac',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
}

function AppStage(props) {
  const [bounds, setBounds] = useState(null)
  const [activities, setActivities] = useState([])

  const next = (result) => {
    props.setStage(1)
    setBounds(result.bounds)
    const sortedActivities = result.activities.sort((a, b) => a.timestamp - b.timestamp)
    setActivities(sortedActivities)
  }

  if (props.stage === 0) {
    return <Upload next={next}/>
  } else if (props.stage === 1) {
    return <Editor activities={activities} bounds={bounds} />
  }
}

const App = () => {
  const [stage, setStage] = useState(0)

  const rollbar = new Rollbar(rollbarConfig);
  window.rollbar = rollbar

  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>
        <Container className="p-5" style={{position: 'relative'}}>
          <div>
            <Trace />
            <Logo />
            <div className='m-s-5'></div>
            <AppStage stage={stage} setStage={setStage}/>
          </div>
        </Container>
      </ErrorBoundary>
    </Provider>
  )
}

export default App
