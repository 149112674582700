import React from "react";
import DataRenderer from "./DataRenderer.js";

import EditControls from './EditControls.js'
import MapLayer from './Map.js'
import SvgRenderer from './SvgRenderer.js'


export default class Editor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            bounds: props.bounds,
            editor_state: {},
        }
    }

    render() {

        const updateBounds = (bounds) => {
            this.setState({bounds: bounds})
        }

        const updateEditorState = (state) => {
            this.setState({editor_state: state})
        }

        return (
        <div className="row">
            <div className="col" key={1}>
                <MapLayer bounds={this.state.bounds} editor_state={this.state.editor_state} updateBounds={updateBounds} activities={this.props.activities} />
                <EditControls updateEditorState={updateEditorState} activities={this.props.activities} />
                <DataRenderer
                    bounds={this.state.bounds}
                    activities={this.props.activities}
                    editor_state={this.state.editor_state}
                />
            </div>
            <div className="col" key={2}>
                <SvgRenderer
                    bounds={this.state.bounds}
                    activities={this.props.activities}
                    editor_state={this.state.editor_state}
                />
            </div>
        </div>
        )
    }
}
